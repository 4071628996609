<template lang="html" src="./manufacturingInformationFFF.template.vue"></template>

<script>
import ManufacturingInformationLynxter from '../manufacturingInformationLynxter/ManufacturingInformationLynxter';

const i18nData = require('./manufacturingInformationFFF.i18n');

export default {
  name: 'ManufacturingInformationFFF',
  components: {
    ManufacturingInformationLynxter
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    manufacturingInformations: {
      type: Object,
      required: true,
    },
  },
  watch: {
    manufacturingInformations: {
      handler: function(val, oldVal) {
        this.manufacturingInformationObject = val;
      },
      deep: true,
      immediate: true,
    },
    'part.analysisStatus': {
      handler: function(val, oldVal) {
        if(val === 'DONE') {
          this.loadingSlice = false;
        } 
      },
      deep: true,
    },
    'part.slicerAnalysis': {
      handler: function(val, oldVal) {
        this.slicerAnalysis = Object.assign({},val);
        if(this.slicerAnalysis.total_volume) {
          this.loadingSlice = false;
        } 
      },
      deep: true,
      immediate: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      manufacturingInformationObject: null,
      slicerAnalysis: null,
      loadingSlice: false,
      lynxterProfile: null,
    };
  },
  created() {
    if(!this.manufacturingInformationObject || !this.manufacturingInformationObject.manufacturing_parameters) {
      this.updateManufacturingInformations();
    }
  },
  methods: {
    validateInfillSparseDensity() {
      if(this.slicerAnalysis.infill_sparse_density < 0) {
        this.slicerAnalysis.infill_sparse_density = 0;
      }
      if(this.slicerAnalysis.infill_sparse_density > 100) {
        this.slicerAnalysis.infill_sparse_density = 100;
      }
      this.slicerAnalysis.infill_sparse_density = Math.round(this.slicerAnalysis.infill_sparse_density);
      this.needToSlice();
    },
    needToSlice() {
      const needToSlice = this.slicerAnalysis.machine_nozzle_size !== this.part.slicerAnalysis.machine_nozzle_size || 
        this.slicerAnalysis.layer_height !== this.part.slicerAnalysis.layer_height ||
        this.slicerAnalysis.infill_sparse_density !== this.part.slicerAnalysis.infill_sparse_density;
      this.$emit('needToSlice', needToSlice);
      this.updateManufacturingInformations();
    },
    improveVolumeReadability(volumeInMillimeter) {
      let improvedValue = 0;
      switch (true) {
      case volumeInMillimeter < 1e3:
        improvedValue = `${Math.ceil(volumeInMillimeter)} mm³`;
        break;
      case volumeInMillimeter >= 1e3 && volumeInMillimeter < 1e6:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e3) * 1e2) / 1e2
        } cm³`;
        break;
      case volumeInMillimeter >= 1e6 && volumeInMillimeter < 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e6) * 1e2) / 1e2
        } dm³`;
        break;
      case volumeInMillimeter >= 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e9) * 1e2) / 1e2
        } m³`;
        break;
      default:
        improvedValue = 'value';
      }
      return improvedValue;
    },
    /**
     * Slice
     */
    saveAndSlice() {
      this.loadingSlice = true;
      this.validateInfillSparseDensity();
      //Save
      const slicerAnalysisParametersBody = {
        machine_nozzle_size: this.slicerAnalysis.machine_nozzle_size,
        layer_height: this.slicerAnalysis.layer_height,
        infill_sparse_density: this.slicerAnalysis.infill_sparse_density,
      };

      this.$apiInstance.modifySupplierPartSlicerAnalysis(this.$route.params.supplierUUID, this.part._id, slicerAnalysisParametersBody)
        .then((data) => {
          this.$emit('refreshPart');
        })
        .catch(err=>{
          this.$emit('refreshPart');
        });
    },
    /**
     * Update lynxter profile
     */
    updatedLynxterProfile(profile) {
      this.lynxterProfile = profile;
      this.updateManufacturingInformations();
    },
    /**
     * Update ManufacturingInformations
     */
    updateManufacturingInformations() {
      this.manufacturingInformationObject.manufacturing_parameters = `${this.$t('machine_nozzle_size')}: ${this.slicerAnalysis.machine_nozzle_size}mm`;
      this.manufacturingInformationObject.manufacturing_parameters += `\n${this.$t('layer_height')}: ${this.slicerAnalysis.layer_height}mm`;
      this.manufacturingInformationObject.manufacturing_parameters += `\n${this.$t('infill_sparse_density')}: ${this.slicerAnalysis.infill_sparse_density}%`;
      if(this.lynxterProfile) {
        this.manufacturingInformationObject.manufacturing_parameters += `\nProfile: ${this.lynxterProfile}`;
      }
      this.$emit('update', this.manufacturingInformationObject);
    }
  },
};
</script>

