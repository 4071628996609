var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "component-pricingRange"
  }, [_c('v-col', [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-6",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-euro-sign ")]), _vm.part.status === 'NEW' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('EstimatedPrices')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('Prices')) + " ")]), _c('v-spacer'), !_vm.useManualPrice && _vm.part.homologation.status === 'SUPPLIER_QUOTATION' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.switchToManualPrice
    }
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-pen ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('ModifyThesePrices')) + " ")])], 1) : _vm._e()], 1), !_vm.useManualPrice && _vm.displaySlider ? _c('v-divider', {
    staticClass: "my-1 mx-4"
  }) : _vm._e(), !_vm.useManualPrice && _vm.displaySlider ? _c('v-card-text', {
    staticClass: "py-0 px-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex align-center py-0 text-subtitle-2",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('FastModification')) + " ")]), _c('v-col', {
    staticClass: "d-flex align-center py-0"
  }, [_c('v-slider', {
    attrs: {
      "hide-details": "auto",
      "min": "25",
      "max": "300",
      "step": "1",
      "type": "number"
    },
    on: {
      "input": _vm.updatePercentage
    },
    model: {
      value: _vm.percentage,
      callback: function callback($$v) {
        _vm.percentage = $$v;
      },
      expression: "percentage"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex align-center py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "text-field-percentage",
    attrs: {
      "width": "100px",
      "append-icon": "fas fa-percentage",
      "type": "number",
      "min": "25",
      "max": "300"
    },
    on: {
      "input": _vm.updatePercentage
    },
    model: {
      value: _vm.percentage,
      callback: function callback($$v) {
        _vm.percentage = $$v;
      },
      expression: "percentage"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-divider', {
    staticClass: "my-1 mx-4"
  }), _vm.pricesForPart && _vm.part.homologation.status === 'SUPPLIER_QUOTATION' ? _c('v-card-text', [_vm.part && _vm.supplier && !_vm.useManualPrice ? _c('PriceEstimationTable', {
    attrs: {
      "part": _vm.part,
      "technology": _vm.part.homologation.technology,
      "material": _vm.part.homologation.material,
      "price-multiplier-percentage": parseInt(_vm.percentage),
      "prices-configurations": _vm.supplier.prices,
      "display-header": false,
      "flat": ""
    }
  }) : _vm._e(), _c('div', {
    class: _vm.useManualPrice ? 'd-block' : 'd-none'
  }, [_c('v-form', {
    ref: "pricesForm",
    attrs: {
      "disabled": _vm.disabled || _vm.editingPrice
    },
    model: {
      value: _vm.pricesValid,
      callback: function callback($$v) {
        _vm.pricesValid = $$v;
      },
      expression: "pricesValid"
    }
  }, _vm._l(_vm.pricesForPart, function (price, index) {
    return _c('v-row', {
      key: index,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "py-1",
      attrs: {
        "cols": "5"
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.$t('From')))]), _c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "type": "number",
        "min": "1",
        "solo": "",
        "rules": [function (value) {
          return !!value || _vm.$t('quantityRequired');
        }],
        "disabled": price.quantityRequired || _vm.part.homologation.status !== 'SUPPLIER_QUOTATION',
        "hide-details": "auto"
      },
      on: {
        "change": function change($event) {
          _vm.verifyPrice();

          _vm.verifyQuantityOfPart(index);

          _vm.modifyPartPrices();
        }
      },
      model: {
        value: price.quantity,
        callback: function callback($$v) {
          _vm.$set(price, "quantity", _vm._n($$v));
        },
        expression: "price.quantity"
      }
    })], 1), _c('v-col', {
      staticClass: "py-1",
      attrs: {
        "cols": "7"
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.$t('priceByPart')))]), _c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "type": "number",
        "step": "0.1",
        "min": "0",
        "solo": "",
        "append-icon": "fas fa-euro-sign",
        "rules": _vm.pricesRangeRules[index],
        "suffix": _vm.$t('TVAExcluded'),
        "disabled": _vm.part.homologation.status !== 'SUPPLIER_QUOTATION',
        "hide-details": "auto"
      },
      on: {
        "change": function change($event) {
          _vm.verifyPrice();

          _vm.modifyPartPrices();
        }
      },
      model: {
        value: price.purchasePrice,
        callback: function callback($$v) {
          _vm.$set(price, "purchasePrice", _vm._n($$v));
        },
        expression: "price.purchasePrice"
      }
    })], 1)], 1);
  }), 1), _c('v-form', {
    ref: "tempPriceForm",
    model: {
      value: _vm.tempPriceValid,
      callback: function callback($$v) {
        _vm.tempPriceValid = $$v;
      },
      expression: "tempPriceValid"
    }
  }, [_vm.tempPrice ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('From')))]), _c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "type": "number",
      "min": "1",
      "solo": "",
      "rules": _vm.tempPriceRules.quantity
    },
    on: {
      "change": _vm.verifyTempPart
    },
    model: {
      value: _vm.tempPrice.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.tempPrice, "quantity", _vm._n($$v));
      },
      expression: "tempPrice.quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('priceByPart')))]), _c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "type": "number",
      "step": "0.1",
      "min": "0",
      "solo": "",
      "append-icon": "fas fa-euro-sign",
      "rules": _vm.tempPriceRules.purchasePrice,
      "suffix": _vm.$t('TVAExcluded'),
      "append-outer-icon": 'fas fa-times'
    },
    on: {
      "change": _vm.verifyTempPart,
      "click:append-outer": _vm.savePrices
    },
    model: {
      value: _vm.tempPrice.purchasePrice,
      callback: function callback($$v) {
        _vm.$set(_vm.tempPrice, "purchasePrice", _vm._n($$v));
      },
      expression: "tempPrice.purchasePrice"
    }
  })], 1)], 1) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [!_vm.editingPrice && _vm.pricesForPart && _vm.part.homologation.status === 'SUPPLIER_QUOTATION' ? _c('v-btn', {
    staticClass: "text-none mt-6",
    attrs: {
      "disabled": _vm.editingPrice || !_vm.pricesValid || _vm.disabled,
      "color": "success"
    },
    on: {
      "click": _vm.addPricesForm
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t('addNewRange')) + " ")], 1) : _vm.editingPrice && _vm.pricesForPart && _vm.part.homologation.status === 'SUPPLIER_QUOTATION' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "disabled": !_vm.tempPriceValid,
      "color": "success"
    },
    on: {
      "click": _vm.addTempPriceFormAndSave
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check-circle ")]), _vm._v(" " + _vm._s(_vm.$t('validate')) + " ")], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.pricesForPart && _vm.part.homologation.status !== 'SUPPLIER_QUOTATION' ? _c('v-card-text', [_c('v-list-item-group', {
    attrs: {
      "multiple": ""
    }
  }, [_c('div', {
    staticClass: "d-flex pa-4"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('quantityTitle')))]), _c('v-spacer'), _c('span', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('priceTitle')))])], 1), _c('v-divider', {
    staticClass: "my-1"
  }), _vm._l(_vm.pricesForPart, function (price, index) {
    return [index !== 0 && index !== _vm.pricesForPart.length ? _c('v-divider', {
      key: "divider-".concat(index)
    }) : _vm._e(), _c('v-list-item', {
      key: "item-".concat(index),
      attrs: {
        "value": price,
        "ripple": false
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm.pricesForPart[index + 1] === undefined ? _c('span', {
      staticClass: "mr-1"
    }, [_vm._v(" " + _vm._s(_vm.$t('from')) + " "), _c('strong', [_vm._v(_vm._s(price.quantity))])]) : price.quantity === _vm.pricesForPart[index + 1].quantity - 1 ? _c('span', [_c('strong', [_vm._v(_vm._s(price.quantity))])]) : [_c('span', [_c('strong', [_vm._v(_vm._s(price.quantity))]), _vm._v(" " + _vm._s(_vm.$t('to')) + " "), _c('strong', [_vm._v(" " + _vm._s(_vm.pricesForPart[index + 1].quantity - 1) + " ")])])]], 2)], 1), _c('v-list-item-action', {
      domProps: {
        "innerHTML": _vm._s(_vm.$tc('price', price.quantity, {
          price: price.purchasePrice
        }))
      }
    })], 1)];
  })], 2)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }