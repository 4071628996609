var render = function () {
  var _vm$slicerAnalysis, _vm$manufacturingInfo, _vm$manufacturingInfo2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-form', [_c('v-container', {
    attrs: {
      "no-gutter": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    class: ['lg', 'xl'].includes(this.$vuetify.breakpoint.name) ? 'pl-0' : '',
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": [0.1, 0.2, 0.3, 0.4],
      "label": _vm.$t('machine_nozzle_size'),
      "outlined": "",
      "disabled": _vm.loadingSlice
    },
    on: {
      "change": _vm.needToSlice
    },
    model: {
      value: _vm.slicerAnalysis.machine_nozzle_size,
      callback: function callback($$v) {
        _vm.$set(_vm.slicerAnalysis, "machine_nozzle_size", $$v);
      },
      expression: "slicerAnalysis.machine_nozzle_size"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": [0.1, 0.2, 0.3, 0.4],
      "label": _vm.$t('layer_height'),
      "outlined": "",
      "disabled": _vm.loadingSlice
    },
    on: {
      "change": _vm.needToSlice
    },
    model: {
      value: _vm.slicerAnalysis.layer_height,
      callback: function callback($$v) {
        _vm.$set(_vm.slicerAnalysis, "layer_height", $$v);
      },
      expression: "slicerAnalysis.layer_height"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    class: ['lg', 'xl'].includes(this.$vuetify.breakpoint.name) ? 'pr-0' : '',
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('infill_sparse_density'),
      "type": "number",
      "step": "1",
      "min": "0",
      "max": "100",
      "outlined": "",
      "disabled": _vm.loadingSlice
    },
    on: {
      "change": _vm.validateInfillSparseDensity
    },
    model: {
      value: _vm.slicerAnalysis.infill_sparse_density,
      callback: function callback($$v) {
        _vm.$set(_vm.slicerAnalysis, "infill_sparse_density", _vm._n($$v));
      },
      expression: "slicerAnalysis.infill_sparse_density"
    }
  })], 1)], 1)], 1)], 1), _c('v-alert', {
    attrs: {
      "color": "orange",
      "dense": "",
      "outlined": "",
      "prominent": "",
      "type": "info"
    }
  }, [((_vm$slicerAnalysis = _vm.slicerAnalysis) === null || _vm$slicerAnalysis === void 0 ? void 0 : _vm$slicerAnalysis.part_volume) > 0 ? [_c('v-list-item', {
    staticClass: "py-0"
  }, [_c('v-list-item-content', {
    staticClass: "py-0"
  }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t('print_time')))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.slicerAnalysis.print_time) + " ")])], 1)], 1), _c('v-list-item', {
    staticClass: "py-0"
  }, [_c('v-list-item-content', {
    staticClass: "py-0"
  }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t('volume')))]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('part_volume')) + " ")]), _vm._v(_vm._s(_vm.improveVolumeReadability(_vm.slicerAnalysis.part_volume)) + " ")]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('support_volume')) + " ")]), _vm._v(_vm._s(_vm.improveVolumeReadability(_vm.slicerAnalysis.support_volume)) + " ")]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('total_volume')) + " ")]), _vm._v(_vm._s(_vm.improveVolumeReadability(_vm.slicerAnalysis.total_volume)) + " ")])], 1)], 1)] : ['WAITING', 'IN_PROGRESS'].includes(_vm.part.analysisStatus) ? [_vm._v(" " + _vm._s(_vm.$t('sliceInProgress')) + " ")] : [_vm._v(" " + _vm._s(_vm.$t('NeedToSlice')) + " ")], _c('v-btn', {
    staticClass: "text-none white--text my-2",
    attrs: {
      "block": "",
      "color": "orange",
      "loading": _vm.loadingSlice,
      "disabled": _vm.part.slicerAnalysis.total_volume > 0 && _vm.slicerAnalysis.machine_nozzle_size === _vm.part.slicerAnalysis.machine_nozzle_size && _vm.slicerAnalysis.layer_height === _vm.part.slicerAnalysis.layer_height && _vm.slicerAnalysis.infill_sparse_density === _vm.part.slicerAnalysis.infill_sparse_density
    },
    on: {
      "click": _vm.saveAndSlice
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('slice')) + " ")])], 1)], 2), _vm.manufacturingInformationObject.printer.brand === 'LYNXTER' && (_vm$manufacturingInfo = _vm.manufacturingInformationObject) !== null && _vm$manufacturingInfo !== void 0 && (_vm$manufacturingInfo2 = _vm$manufacturingInfo.printer) !== null && _vm$manufacturingInfo2 !== void 0 && _vm$manufacturingInfo2.model ? _c('ManufacturingInformationLynxter', {
    attrs: {
      "part": _vm.part,
      "manufacturing-informations": _vm.manufacturingInformationObject
    },
    on: {
      "update": _vm.updatedLynxterProfile
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }