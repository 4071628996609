var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', {
    attrs: {
      "items": _vm.availableProfile,
      "filter": _vm.customProfileFilter,
      "outlined": "",
      "label": _vm.$t('SelectLynxterProfile'),
      "rules": [function (value) {
        return !!value || _vm.$t('profileIsRequired');
      }],
      "item-text": "name",
      "item-value": "name"
    },
    on: {
      "change": _vm.updateSelectedProfile
    },
    model: {
      value: _vm.selectedProfile,
      callback: function callback($$v) {
        _vm.selectedProfile = $$v;
      },
      expression: "selectedProfile"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }