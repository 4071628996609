<template lang="html" src="./manufacturingInformation.template.vue"></template>

<style lang="scss" src="./manufacturingInformation.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
import ManufacturingInformationFFF from '../manufacturingInformationFFF/ManufacturingInformationFFF';

const i18nData = require('./manufacturingInformation.i18n');

export default {
  name: 'ManufacturingInformation',
  components: {
    ManufacturingInformationFFF
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    manufacturingInformations: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayNestingSettings: {
      type: Boolean,
      default: true
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    displayCloseButton: {
      type: Boolean,
      default: false
    },
    displaySaveButton: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    manufacturingInformations: {
      handler: function(val, oldVal) {
        /**
         * Initialise the object for v-model in the duplicated object
         */
        if(!val?.printer) {
          val.printer = {
            brand: null,
            model: null,
          };
        }
        if(!val.printer.brand) {
          val.printer.brand = null;
        }
        if(!val.printer.model) {
          val.printer.model = null;
        }
        /**
         * Save the object as a duplicated object, without the observer for the reactivity of the cloned object
         */
        this.manufacturingInformationObject = JSON.parse(JSON.stringify(val));
      },
      deep: true,
      immediate: true
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      printers: null,
      printersBrand: [],
      printersModel: [],
      manufacturingInformationObject: null,
      manufacturingInformationsValid: false,
      loading: false,
      needToSliceStatus: false
    };
  },
  created() {
    this.getSupplierPrinters();
  },
  methods: {
    isValid() {
      return this.$refs.manufacturingInformationsForm.validate();
    },
    modifyManufacturingInformationsForLibraryBCM() {
      this.$emit('modified');
    },
    /**
     * GET SUPPLIER PRINTERS
     */
    getSupplierPrinters() {
      this.$apiInstance
        .getSupplierPrinters(this.$route.params.supplierUUID)
        .then(
          (printersData) => {
            this.printers = printersData;
            this.getBrands();
            if(this.manufacturingInformations.printer) {
              this.getModels(this.manufacturingInformations.printer.brand);
            }
          },
          (error) => {
            /**
             * ERROR GET PRINTER
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * GET BRANDS
     */
    getBrands() {
      this.printersBrand = [];
      for (let i = 0; i < this.printers.length; i++) {
        if (
          this.printers[i].technology === this.part.homologation.technology &&
          !this.printersBrand.includes(
            this.printers[i].brand.split('_').join(' ')
          )
        ) {
          this.printersBrand.push(this.printers[i].brand.split('_').join(' '));
        }
      }
    },
    /**
     * GET MODELS
     */
    getModels(brand) {
      this.printersModel = [];
      for (let i = 0; i < this.printers.length; i++) {
        if (
          this.printers[i].brand.split('_').join(' ') === brand &&
          this.printers[i].technology === this.part.homologation.technology
        ) {
          this.printersModel.push(this.printers[i].model.split('_').join(' '));
        }
      }
    },
    /**
     * Update ManufacturingInformations
     */
    updateManufacturingInformations() {
      this.$emit('update', this.manufacturingInformationObject);
    },
    close() {
      this.$emit('close');
    },
    saveManufacturingInformation() {
      this.loading = true;
      if(this.needToSliceStatus) {
        this.$refs.ManufacturingInformationFFF.saveAndSlice();
      }
      this.$emit('saveManufacturingInformation', this.manufacturingInformationObject);
    },
    stopLoading() {
      this.loading = false;
    },
    refreshPart() {
      this.$emit('refreshPart');
    },
    needToSlice(status) {
      this.needToSliceStatus = status;
    }
  },
};
</script>

