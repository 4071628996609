<template lang="html" src="./manufacturingInformationLynxter.template.vue"></template>

<script>
const i18nData = require('./manufacturingInformationLynxter.i18n');

export default {
  name: 'ManufacturingInformationLynxter',
  props: {
    part: {
      type: Object,
      required: true,
    },
    manufacturingInformations: {
      type: Object,
      required: true,
    },
  },
  watch: {
    manufacturingInformations: {
      handler: function(val, oldVal) {
        this.parsePrinterInformations();
      },
      deep: true,
      immediate: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      selectedProfile: null,
      printerModel: null,
      printerToolType: null,
      availableProfile: [],
      profiles: [
        'S600D-110_FIL11_175_BD-100_PLA_SMT_v1.0.1.fff',
        'S600D-110_FIL11_175_BD-100_PLA_TAG_v1.0.1.fff',
        'S600D-110_FIL11_175_BD-100_PLA-MAX_PMK_v1.0.1.fff',
        'S600D-110_FIL11_175_BD-100_PLA-R_KMY_v1.0.4.fff',
        'S600D-110_FIL11_175_BD-100_316LX_BASF_v1.0.1.fff',
        'S600D-110_FIL11_175_BD-100_ABS_EMT_v1.0.2.fff',
        'S600D-110_FIL11_175_BD-100_ABS_TTM_v1.0.5.fff',
        'S600D-110_FIL11_175_BD-100_ABS_ESD_KMY_v1.0.2.fff',
        'S600D-110_FIL11_175_BD-100_PETG-CF_KMY_v1.0.4',
        'S600D-110_FIL11_175_BD-100_PETG-S_KMY_v1.1.3.fff',
        'S600D-110_FIL11_175_BD-100_PC-Max_PMK_v1.0.5.fff',
        'S600D-110_FIL11_175_BD-100_PCL_ESN_v1.0.1.fff',
        'S600D-110_FIL11_175_BD-100_PA6-CF_PMK_v1.0.6.fff',
        'S600D-110_FIL11_175_BD-100_PP_VBT_v1.0.2.fff',
        'S600D-110_FIL11_175_BD-100_PEKK_3DXT_v1.0.1.fff',
        'S600D-110_FIL11_175_BD-100_TPU95_PMK_v1.0.1.fff',
        'S600D-110_FIL11_175_BD-100_TPC-ESD_KMY_v1.0.2.fff',
        'S600D-110_FIL33_175_BD-120_HF_PLA-R_KMY_v1.1.7.fff',
        'S600D-110_FIL33_175_BD-120_HF_PLA-MAX_PMK_v1.0.1.fff',
        'S600D-110_FIL33_175_BD-120_HF_ABS_EMT_v1.0.3.fff',
        'S600D-110_FIL33_175_BD-120_HF_ABS_TTM_v1.0.4.fff',
        'S600D-110_FIL33_175_BD-120_HF_ABS-EC_KMY_v1.0.0.fff',
        'S600D-110_FIL33_175_BD-120_HF_ABS-ESD_KMY_v1.0.3.fff',
        'S600D-110_FIL33_175_BD-120_HF_PETG-CF_KMY_v1.0.2.fff',
        'S600D-110_FIL33_175_BD-120_HF_PETG-S_KMY_v1.0.5.fff',
        'S600D-110_FIL33_175_BD-120_HF_PC-MAX_PMK_v1.0.4.fff',
        'S600D-110_FIL33_175_BD-120_HF_PA6-CF_PMK_v1.0.7.fff',
        'S600D-110_FIL33_175_BD-120-HF_PP_VBT_v1.0.2.fff',
        'S600D-110_FIL33_175_BD-120-HF_TPC-ESD_KMY_v1.0.2.fff',
        'S600D-110_LIQ11_060_3-110_34shoreA_v2.0.0.fff',
        'S600D-110_LIQ21_060_33-110_2525_COP_v0.0.fff',
        'S600D-110_LIQ21_060_33-110_4025_COP_v1.0.1.fff',
        'S600D-110_PAS11_060_5-110_Stoneware_CG841_Light_Brown_CRL_v1.1.0.fff',
        'S600D-110_PAS11_060_5-110_Earthenware_CF510B_Light_Brown_CRL_v1.1.0.fff',
        'S600D-110_PAS11_060_5-110_Porcelain_PB300T_White_CRL_v1.1.0.fff'
      ]
    };
  },
  created() {
  },
  methods: {
    parsePrinterInformations() {
      /**
       * Parse profile attribute in manufacturing parameters
       * profile: <name>\n
       */
      this.selectedProfile = null;
      const regex = /Profile:\s(.*.fff)/m;
      const match = this.manufacturingInformations.manufacturing_parameters.match(regex);
      if(match && match.length >= 2) {
        this.selectedProfile = match[1];
      }
      /**
       * Retrieve information
       */
      this.printerModel = this.manufacturingInformations.printer.model.split(' ')[0];
      this.printerToolType = this.manufacturingInformations.printer.model.split(' ')[1];
      this.parseLynxterProfiles();
    },
    parseLynxterProfiles() {
      const matchedProfiles = [
        { header: this.$t('matchedProfiles') },
      ];
      const otherProfiles = [
        { divider: true },
        { header: this.$t('otherProfiles') },
      ];
      const regex = new RegExp(`${this.printerModel}-\\d+_${this.printerToolType}_.*`);
      this.profiles.forEach(profile => {
        if(profile.match(regex)) {
          if(profile.toLowerCase().includes(this.part.homologation.material.toLowerCase())) {
            matchedProfiles.push({ name: profile, group: this.$t('matchedProfiles')});
          }else{
            otherProfiles.push({ name: profile, group: this.$t('otherProfiles')});
          }
        }
      });
      this.availableProfile = matchedProfiles.concat(otherProfiles);
    },
    /**
     * Custom Filter
     */
    customProfileFilter(item, queryText, itemText) {
      if(item.header) {
        return true;
      }else if(item.divider) {
        return true;
      }
      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    /**
     * Update ManufacturingInformations
     */
    updateSelectedProfile() {
      this.$emit('update', this.selectedProfile);
    }
  },
};
</script>

