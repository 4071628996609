var render = function () {
  var _vm$part$homologation;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-manufacturingInformation"
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-6",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-industry ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('manufacturingInformations')) + " ")]), _c('v-spacer'), _vm.displayCloseButton ? _c('v-btn', {
    attrs: {
      "color": "info",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v(" fas fa-times ")])], 1) : _vm._e()], 1), _vm.part && _vm.editMode ? _c('v-card-text', [_c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-row', [_c('v-col', [_c('v-form', {
    ref: "manufacturingInformationsForm",
    attrs: {
      "disabled": _vm.disabled || _vm.part.homologation.status === 'REJECTED'
    }
  }, [_c('v-card-subtitle', {
    staticClass: "pt-0 d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mr-2",
    attrs: {
      "src": require("../../../public/img/icons/icon3DprinterDark.png"),
      "contain": "",
      "aspect-ratio": "1",
      "max-width": "24",
      "max-height": "24"
    }
  }), _c('h4', [_vm._v(_vm._s(_vm.$t("Printer")))])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-select', {
    staticClass: "mr-2",
    attrs: {
      "items": _vm.printersBrand,
      "label": _vm.$t('brand'),
      "rules": [function (value) {
        return !!value || _vm.$t('BrandIsRequired');
      }],
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        _vm.manufacturingInformationObject.printer.model = null;

        _vm.getModels(_vm.manufacturingInformationObject.printer.brand);
      }
    },
    model: {
      value: _vm.manufacturingInformationObject.printer.brand,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturingInformationObject.printer, "brand", $$v);
      },
      expression: "manufacturingInformationObject.printer.brand"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.printersModel,
      "label": _vm.$t('model'),
      "rules": [function (value) {
        return !!value || _vm.$t('ModelIsRequired');
      }],
      "outlined": ""
    },
    on: {
      "input": _vm.updateManufacturingInformations
    },
    model: {
      value: _vm.manufacturingInformationObject.printer.model,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturingInformationObject.printer, "model", $$v);
      },
      expression: "manufacturingInformationObject.printer.model"
    }
  })], 1), _c('v-card-subtitle', {
    staticClass: "pt-0"
  }, [_c('h4', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-certificate ")]), _vm._v(" " + _vm._s(_vm.$t("MaterialAndManufacturingSettings")) + " ")], 1)]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('Material'),
      "rules": [function (value) {
        return !!value || _vm.$t('MaterialIsRequired');
      }],
      "outlined": ""
    },
    on: {
      "change": _vm.updateManufacturingInformations
    },
    model: {
      value: _vm.manufacturingInformationObject.material,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturingInformationObject, "material", $$v);
      },
      expression: "manufacturingInformationObject.material"
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0"
  }, [_vm.part.homologation.technology === 'FFF' ? _c('ManufacturingInformationFFF', {
    ref: "ManufacturingInformationFFF",
    attrs: {
      "part": _vm.part,
      "manufacturing-informations": _vm.manufacturingInformationObject
    },
    on: {
      "update": function update($event) {
        _vm.manufacturingInformationObject = $event;

        _vm.updateManufacturingInformations();
      },
      "refreshPart": _vm.refreshPart,
      "needToSlice": _vm.needToSlice
    }
  }) : _c('v-textarea', {
    staticClass: "mr-2",
    attrs: {
      "disabled": !_vm.manufacturingInformationObject.printer.model,
      "auto-grow": true,
      "rows": 4,
      "label": _vm.$t('manufacturingParameters'),
      "outlined": ""
    },
    on: {
      "change": _vm.updateManufacturingInformations
    },
    model: {
      value: _vm.manufacturingInformationObject.manufacturing_parameters,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturingInformationObject, "manufacturing_parameters", $$v);
      },
      expression: "manufacturingInformationObject.manufacturing_parameters"
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0"
  }, [_c('v-textarea', {
    attrs: {
      "auto-grow": true,
      "rows": 4,
      "label": _vm.$t('finish'),
      "outlined": ""
    },
    on: {
      "change": _vm.updateManufacturingInformations
    },
    model: {
      value: _vm.manufacturingInformationObject.finish,
      callback: function callback($$v) {
        _vm.$set(_vm.manufacturingInformationObject, "finish", $$v);
      },
      expression: "manufacturingInformationObject.finish"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.displayNestingSettings ? _c('v-card-subtitle', [_c('h4', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-cog ")]), _vm._v(" " + _vm._s(_vm.$t("NestingSettings")) + " ")], 1)]) : _vm._e(), _vm.displayNestingSettings ? _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "font-weight-medium width-30-percent"
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("ZAxisRotation")))]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "max-width": "350"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({
                staticClass: "ml-2 mb-1",
                attrs: {
                  "small": ""
                }
              }, on), [_vm._v(" fas fa-question-circle ")])];
            }
          }], null, false, 3589344309)
        }, [_c('v-card', {
          staticClass: "pt-0"
        }, [_c('v-card-text', {
          staticClass: "pt-0 text-justify"
        }, [_c('v-row', {
          staticClass: "pt-0"
        }, [_c('v-col', {
          staticClass: "justify-flex-around",
          attrs: {
            "cols": "12"
          }
        }, [_vm.part.zRotationAllowed ? _c('v-img', {
          attrs: {
            "src": require("../../../public/img/icons/RotationAllowed.png"),
            "contain": "",
            "aspect-ratio": "1",
            "height": "150"
          }
        }) : _c('v-img', {
          attrs: {
            "src": require("../../../public/img/icons/RotationForbidden.png"),
            "contain": "",
            "aspect-ratio": "1",
            "height": "150"
          }
        })], 1)], 1), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('TooltipRotationMessage')) + " ")])], 1)], 1)], 1)], 1), _c('td', [_c('v-switch', {
          attrs: {
            "disabled": _vm.part.homologation.status === 'REJECTED',
            "label": _vm.part.zRotationAllowed ? _vm.$t('Allow') : _vm.$t('Forbid')
          },
          model: {
            value: _vm.part.zRotationAllowed,
            callback: function callback($$v) {
              _vm.$set(_vm.part, "zRotationAllowed", $$v);
            },
            expression: "part.zRotationAllowed"
          }
        })], 1)])])];
      },
      proxy: true
    }], null, false, 3707829778)
  }) : _vm._e()], 1) : _vm._e(), _vm.part && _vm.manufacturingInformations && !_vm.editMode ? _c('v-card-text', [_c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "font-weight-medium width-30-percent"
        }, [_vm._v(" " + _vm._s(_vm.$t("brand")) + " ")]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.manufacturingInformations.printer.brand))])])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t("model")) + " ")]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.manufacturingInformations.printer.model))])])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t("Material")) + " ")]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.manufacturingInformations.material))])])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t("manufacturingParameters")) + " ")]), _c('td', [_c('span', [_vm._v(" " + _vm._s(_vm.manufacturingInformations.manufacturing_parameters) + " ")])])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t("finish")) + " ")]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.manufacturingInformations.finish))])])])])];
      },
      proxy: true
    }], null, false, 3304004738)
  }), _c('v-card-subtitle', [_c('h4', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-cog ")]), _vm._v(" " + _vm._s(_vm.$t("NestingSettings")) + " ")], 1)]), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "font-weight-medium width-30-percent"
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("ZAxisRotation")))]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "max-width": "350"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-icon', _vm._g({
                staticClass: "ml-2 mb-1",
                attrs: {
                  "small": ""
                }
              }, on), [_vm._v(" fas fa-question-circle ")])];
            }
          }], null, false, 3589344309)
        }, [_c('v-card', {
          staticClass: "pt-0"
        }, [_c('v-card-text', {
          staticClass: "pt-0 text-justify"
        }, [_c('v-row', {
          staticClass: "pt-0"
        }, [_c('v-col', {
          staticClass: "justify-flex-around",
          attrs: {
            "cols": "12"
          }
        }, [_vm.part.zRotationAllowed ? _c('v-img', {
          attrs: {
            "src": require("../../../public/img/icons/RotationAllowed.png"),
            "contain": "",
            "aspect-ratio": "1",
            "height": "150"
          }
        }) : _c('v-img', {
          attrs: {
            "src": require("../../../public/img/icons/RotationForbidden.png"),
            "contain": "",
            "aspect-ratio": "1",
            "height": "150"
          }
        })], 1)], 1), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('TooltipRotationMessage')) + " ")])], 1)], 1)], 1)], 1), _c('td', [_c('v-switch', {
          attrs: {
            "disabled": _vm.part.homologation.status === 'REJECTED',
            "label": _vm.part.zRotationAllowed ? _vm.$t('Allow') : _vm.$t('Forbid')
          },
          on: {
            "change": _vm.modifyManufacturingInformationsForLibraryBCM
          },
          model: {
            value: _vm.part.zRotationAllowed,
            callback: function callback($$v) {
              _vm.$set(_vm.part, "zRotationAllowed", $$v);
            },
            expression: "part.zRotationAllowed"
          }
        })], 1)])])];
      },
      proxy: true
    }], null, false, 1237812984)
  })], 1) : _vm._e(), _vm.displaySaveButton && ((_vm$part$homologation = _vm.part.homologation) === null || _vm$part$homologation === void 0 ? void 0 : _vm$part$homologation.status) !== 'REJECTED' ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "large": "",
      "loading": _vm.loading,
      "disabled": !_vm.manufacturingInformationObject.printer.brand || !_vm.manufacturingInformationObject.printer.model
    },
    on: {
      "click": _vm.saveManufacturingInformation
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-save ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Save')) + " ")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }