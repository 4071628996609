<template lang="html" src="./pricingRange.template.vue"></template>

<style lang="scss" src="./pricingRange.scss"></style>

<script>
import { ApiErrorParser,  FormulaPricing, EventBus } from '@cloudmanufacturingtechnologies/portal-components';
import PriceEstimationTable from '../../components/priceEstimationTable/PriceEstimationTable';


const i18nData = require('./pricingRange.i18n');

export default {
  name: 'PricingRange',
  components: {
    PriceEstimationTable,
  },
  props: {
    part: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    displaySlider: {
      type: Boolean,
      default: false
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      editingPrice: false,
      pricesRangeRules: [],
      pricesForPart: [],
      tempPrice: null,
      disablePricingRange: true,
      keyComponent: 0,
      tempPriceValid: false,
      pricesValid: false,
      tempPriceRules: { quantity: [], purchasePrice: [] },
      percentage: 100,
      lastPercentageUpdate: 0,
      precision: 10E2,
      /**
       * Automatic Price based on formula
       */
      supplier: null,
      supplierFormula: null,
      useManualPrice: false,
    };
  },
  created() {
    this.percentage = this.part.homologation.priceMultiplierPercentage;
    this.addQuantityRequiredIfNecessary();
    this.updatePriceRangeRules();
    this.testPrices();
    this.addPresetQuantity();
    this.getSupplier(); // Analyse if formula is configured and try to use formula
    EventBus.$on('priceUpdated', this.addQuantityRequiredIfNecessary);
  },
  destroyed() {
    EventBus.$off('priceUpdated', this.addQuantityRequiredIfNecessary);
  },
  beforeUpdate() {
    this.updatePriceRangeRules();
  },
  methods: {
    /**
     * GET SUPPLIER
     */
    getSupplier() {
      this.$apiInstance.getSupplier(this.$route.params.supplierUUID)
        .then(supplier => {
          this.supplier = supplier;
          /**
           * Test if formula exist
           */
          this.supplier.prices.forEach(priceConfiguration => {
            if(priceConfiguration.technology === this.part.homologation.technology && priceConfiguration.material === this.part.homologation.material) {
              this.supplierFormula = priceConfiguration.formula;
              return;
            }
          });
          /**
           * If formula is configured
           * Look if the price already exists
           */
          if(this.supplierFormula) {
            let emptyPrice = true;
            this.pricesForPart.forEach(price=>{
              if(price.purchasePrice) {
                emptyPrice = false;
                return;
              }
            });
            if(emptyPrice) {
              /**
               * Fill default purchase price with the formula
               */
              this.pricesForPart.forEach(price=>{
                price.purchasePrice = Math.round(this.precision * FormulaPricing.getPrice(this.supplierFormula,this.part,price.quantity)) / this.precision;
              });
              this.updatePriceRangeRules();
              this.testPrices();
              /**
               * Save these prices
               */
              setTimeout(this.modifyPartPrices, 500);
            }else{
              /**
               * Analyse if price has been manually modified
               */
              this.pricesForPart.forEach(price=>{
                const autoCalculatedPrice = Math.round(this.precision * (this.percentage / 100) * FormulaPricing.getPrice(this.supplierFormula,this.part,price.quantity)) / this.precision;
                if(price.purchasePrice !== autoCalculatedPrice) {
                  /**
                   * Price has been modified, use manual Tool
                   */
                  this.switchToManualPrice();
                  return;
                }
              });
            }
          }else{
            /**
             * Not configured, use manual price tool
             */
            this.switchToManualPrice();
          }
          this.emitPricesValidation();
        })
        .catch((error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        });
    },
    isValid() {
      return this.validatePricesForm();
    },
    isValidTemp() {
      return this.validateTempPriceForm();
    },
    switchToManualPrice() {
      this.useManualPrice = true;
      this.$emit('switchToManualPrice');
    },
    updatePercentage() {
      const now = Date.now();
      this.lastPercentageUpdate = now;
      setTimeout(() => {
        if(this.lastPercentageUpdate === now) {
          this.pricesForPart.forEach(price => {
            price.purchasePrice = Math.round(this.precision * (this.percentage / 100) * FormulaPricing.getPrice(this.supplierFormula, this.part, price.quantity)) / this.precision;
          });
          this.$emit('updatePricesWithPercentage', JSON.parse(JSON.stringify(this.pricesForPart)), this.percentage);
        }
      }, 100, this);
    },
    modifyPartPrices() {
      if (
        this.validatePricesForm() &&
        this.validateTempPriceForm()
      ) {
        this.$emit('modified', this.pricesForPart);
      }
    },
    addQuantityRequiredIfNecessary() {
      if (this.part.homologation.prices) {
        this.pricesForPart = [];
        for (let i = 0; i < this.part.homologation.prices.length; i++) {
          const price = Object.assign({}, this.part.homologation.prices[i]);
          if (price.quantity === 1) {
            price.quantityRequired = true;
          }
          this.pricesForPart.push(price);
        }
      }
    },
    /**
     * ADD A NEW PRICE IN THE FORM PRICES
     */
    addPricesForm() {
      if (this.pricesValid) {
        this.tempPrice = {
          quantity: null,
          purchasePrice: null,
        };
        this.tempPriceRules = {
          quantity: [],
          purchasePrice: [],
        };
        this.editingPrice = true;
        this.verifyTempPart();
      }
    },
    /**
     * REMOVE PRICE FORM
     */
    removePriceForm() {
      this.tempPrice = null;
      this.editingPrice = false;
      this.tempPriceRules = {
        quantity: [],
        purchasePrice: [],
      };
    },
    /**
     * ADD TEMPORARILY PRICE FORM
     */
    addTempPriceFormAndSave() {
      if (this.validateTempPriceForm()) {
        this.pricesForPart.push({
          quantity: this.tempPrice.quantity,
          purchasePrice: this.tempPrice.purchasePrice,
        });
        this.savePrices();
      }
    },
    /**
     * ADD PRESET QUANTITY
     */
    addPresetQuantity() {
      if (
        this.part.dna.typeOfProduction === 'prototype'
      ) {
        const fieldToAdd = [2, 3, 4, 5, 10, 20];
        let fieldNoNeedToBeAdd = false;
        if (
          this.pricesForPart.length === 0 ||
          this.pricesForPart[0].purchasePrice === null
        ) {
          fieldNoNeedToBeAdd = true;
        }
        if (fieldNoNeedToBeAdd) {
          for (const field of fieldToAdd) {
            this.pricesForPart.push({
              quantity: field,
              purchasePrice: null,
            });
          }
        }
      } else {
        const fieldToAdd = [2, 3, 4, 5, 10, 20, 50, 100];
        let fieldNoNeedToBeAdd = false;

        if (
          this.pricesForPart.length === 0 ||
          this.pricesForPart[0].purchasePrice === null
        ) {
          fieldNoNeedToBeAdd = true;
        }

        if (fieldNoNeedToBeAdd) {
          for (const field of fieldToAdd) {
            this.pricesForPart.push({
              quantity: field,
              purchasePrice: null,
            });
          }
        }
      }
      this.updatePriceRangeRules();
    },
    /**
     * VERIFICATION OF QUANTITY IN THE FORM PRICES
     */
    verifyQuantityOfPart(index) {
      const price = this.pricesForPart[index];
      if (!price.quantityRequired) {
        this.pricesForPart.forEach((price2, index2) => {
          if (index !== index2) {
            if (price.quantity === price2.quantity) {
              price.quantity = null;
              this.$notification.notify('SUCCESS',this.$t('cantHaveTwoPriceForSameQantity'));
              return;
            }
          }
        });
      }
    },
    validateTempPriceForm() {
      this.tempPriceValid = this.$refs.tempPriceForm.validate();
      this.emitPricesValidation();
      return this.tempPriceValid;
    },
    validatePricesForm() {
      this.pricesValid = this.$refs.pricesForm?.validate();
      this.emitPricesValidation();
      return this.pricesValid;
    },
    emitPricesValidation() {
      this.$emit('pricesValidationChanged', !this.useManualPrice || (!this.tempPrice && this.pricesValid));
    },
    /**
     * TESTING PRICE FOR 1 AND DNA QUANTITY
     */
    testPrices() {
      let quantityUnitary = false;
      this.pricesForPart.forEach((price) => {
        if (price.quantity === 1) {
          quantityUnitary = true;
        }
      });
      if (!quantityUnitary) {
        this.pricesForPart.push({
          quantity: 1,
          purchasePrice: null,
          quantityRequired: true,
        });
      }
    },
    /**
     * SAVING PRICES
     */
    savePrices() {
      const prices = [];
      this.pricesForPart.forEach((price) => {
        prices.push({
          quantity: price.quantity,
          purchasePrice: price.purchasePrice,
        });
      });
      this.pricesForPart = prices;
      this.$emit('save', this.pricesForPart);
      this.removePriceForm();
      this.validateTempPriceForm();
    },
    /**
     * VERIFY PRICE RANGE
     */
    verifyPrice() {
      this.updatePriceRangeRules();
      setTimeout(this.validatePricesForm, 500);
    },

    updatePriceRangeRules() {
      const pricesRange = [];
      this.pricesForPart.forEach((current, index) => {
        const price = {
          quantityMin: null,
          quantityMax: null,
          min: current.purchasePrice,
          max: current.purchasePrice,
        };

        //On cherche la quantité la plus proche inférieur et supérieur
        this.pricesForPart.forEach((test, index2) => {
          if (
            index2 !== index &&
            current.quantity !== test.quantity &&
            current.quantity > 0 &&
            current.purchasePrice > 0 &&
            test.purchasePrice &&
            test.purchasePrice > 0
          ) {
            if (test.quantity < current.quantity) {
              //Plus petite quantité donc le prix doit etre suppérieur
              if (!price.quantityMin || test.quantity >= price.quantityMin) {
                price.quantityMin = test.quantity; //la quantite la plus basse
                price.max = test.purchasePrice; //le prix le plus haut
              }
            } else {
              //Plus grande quantité donc le prix doit etre inférieur
              if (!price.quantityMax || test.quantity <= price.quantityMax) {
                price.quantityMax = test.quantity; //la quantite la plus haute
                price.min = test.purchasePrice; //le prix le plus bas
              }
            }
          }
        });

        const rules = [];
        //push empty
        rules.push((value) => !!value || value === 0 || this.$t('priceRequired'));
        if (!price.quantityMin) {
          price.max = null;
          //quantity min
          rules.push(
            (value) =>
              value >= price.min ||
              this.$t('PriceRangeSupTo', {
                min: price.min,
              })
          );
        } else if (!price.quantityMax) {
          price.min = null;
          //quantity max
          rules.push(
            (value) =>
              value <= price.max ||
              this.$t('PriceRangeInfTo', {
                max: price.max,
              })
          );
          rules.push(
            (value) =>
              value > 0 ||
              this.$t('PriceRangeSupTo', {
                max: 0,
              })
          );
        } else {
          //Between
          rules.push(
            (value) =>
              (value >= price.min && value <= price.max) ||
              this.$t('PriceRangeBetween', {
                min: price.min,
                max: price.max,
              })
          );
        }
        //push rules
        pricesRange.push(rules);
      });
      this.pricesRangeRules = pricesRange;
    },

    verifyTempPart() {
      const tempPriceRules = {
        quantity: [(value) => !!value || this.$t('quantityRequired')],
        purchasePrice: [(value) => !!value || this.$t('priceRequired')],
      };

      const price = {
        quantityMin: null,
        quantityMax: null,
        min: this.tempPrice.purchasePrice,
        max: this.tempPrice.purchasePrice,
      };
      this.pricesForPart.forEach((test, index) => {
        //Test if quantity already exist
        if (
          this.tempPrice.quantity &&
          this.tempPrice.quantity > 0 &&
          test.quantity === this.tempPrice.quantity
        ) {
          tempPriceRules.quantity.push(
            (value) => value !== test.quantity || this.$t('QuantityAlreadyExist')
          );
        }
        //Test price range
        if (
          this.tempPrice.quantity !== test.quantity &&
          this.tempPrice.quantity > 0 &&
          this.tempPrice.purchasePrice > 0 &&
          test.purchasePrice &&
          test.purchasePrice > 0
        ) {
          if (test.quantity < this.tempPrice.quantity) {
            //Plus petite quantité donc le prix doit etre suppérieur
            if (!price.quantityMin || test.quantity >= price.quantityMin) {
              price.quantityMin = test.quantity; //la quantite la plus basse
              price.max = test.purchasePrice; //le prix le plus haut
            }
          } else {
            //Plus grande quantité donc le prix doit etre inférieur
            if (!price.quantityMax || test.quantity <= price.quantityMax) {
              price.quantityMax = test.quantity; //la quantite la plus haute
              price.min = test.purchasePrice; //le prix le plus bas
            }
          }
        }
      });
      if (!price.quantityMin) {
        price.max = null;
        //quantity min
        tempPriceRules.purchasePrice.push(
          (value) =>
            value > price.min ||
            this.$t('PriceRangeSupTo', {
              min: price.min,
            })
        );
      } else if (!price.quantityMax) {
        price.min = null;
        //quantity max
        tempPriceRules.purchasePrice.push(
          (value) =>
            value < price.max ||
            this.$t('PriceRangeInfTo', {
              max: price.max,
            })
        );
        tempPriceRules.purchasePrice.push(
          (value) =>
            value > 0 ||
            this.$t('PriceRangeSupTo', {
              max: 0,
            })
        );
      } else {
        //Between
        tempPriceRules.purchasePrice.push(
          (value) =>
            (value > price.min && value < price.max) ||
            this.$t('PriceRangeBetween', {
              min: price.min,
              max: price.max,
            })
        );
      }
      this.tempPriceRules = tempPriceRules;
      setTimeout(this.validateTempPriceForm, 200);
    },
  },
};
</script>

